import React from 'react';
import {Link} from "react-router-dom"
import {
    Github,
    Globe,
    Server,
    Terminal,
    Check,
    RefreshCw,
    Lock,
    ArrowRight
} from 'lucide-react';
import { FaGithub } from "react-icons/fa";

const Tutorial = ({ user, signOutWithGitHub, signInWithGitHub }) => {

    const steps = [
        {
            icon: Globe,
            title: "Go to CoolCloud",
            description: "Visit coolcloud.app and sign up or log in to your account.",
        },
        {
            icon: Github,
            title: "Connect GitHub",
            description: "Link your GitHub account to access your repositories.",
        },
        {
            icon: Server,
            title: "Create Web Service",
            description: "Click 'Web Service' in your dashboard to start a new deployment.",
        },
        {
            icon: Terminal,
            title: "Configure Deployment",
            description: "Select your repository, set the name, location, and other details.",
        },
        {
            icon: RefreshCw,
            title: "Deploy",
            description: "Click 'Deploy' and wait for the process to complete.",
        },
        {
            icon: Lock,
            title: "Access Your App",
            description: "Use the provided HTTPS endpoint to securely access your deployed application.",
        }
    ];

    return (
        <>
                        <header className="bg-white shadow-sm fixed w-full z-50">
                <div className="container mx-auto flex justify-between items-center py-4 px-6">
                    <div className="flex items-center">
                        <Link to="/" className="text-2xl cursor-pointer font-bold text-indigo-600">CoolCloud</Link>
                    </div>
                    <div className="flex items-center space-x-4">
                        <a href="mailto:hector.diaz@pucp.edu.pe" className="text-gray-600 sm:flex hidden hover:text-indigo-600 transition-colors">Contact</a>
                        {user && (
                            <Link to="/dashboard" className="text-gray-600 hidden sm:flex hover:text-indigo-600 transition-colors">
                                Dashboard
                            </Link>
                        )}
                        <button
                            onClick={user? signOutWithGitHub : signInWithGitHub}
                            className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-full transition-colors flex items-center"
                        >
                            <FaGithub className="mr-2" />
                            {user ? "Sign out" : "Sign up"}
                        </button>
                    </div>
                </div>
            </header>
            <div className="bg-gradient-to-b from-gray-50 to-white py-32 px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl mx-auto">
                    <h2 className="text-4xl font-extrabold text-gray-900 text-center mb-12">
                        Deploy Your Web Service on CoolCloud
                    </h2>
                    <div className="relative">
                        {steps.map((step, index) => (
                            <div key={index} className={`flex items-center mb-12 ${index % 2 === 0 ? '' : 'flex-row-reverse'}`}>
                                <div className={`w-1/2 ${index % 2 === 0 ? 'pr-8 text-right' : 'pl-8 text-left'}`}>
                                    <h3 className="text-xl font-semibold text-indigo-600 mb-2">{step.title}</h3>
                                    <p className="text-gray-600">{step.description}</p>
                                </div>
                                <div className="w-12 h-12 flex-shrink-0 mx-4">
                                    <div className="w-12 h-12 border-4 border-indigo-200 bg-white rounded-full flex items-center justify-center">
                                        <step.icon className="h-6 w-6 text-indigo-500" />
                                    </div>
                                </div>
                                <div className="w-1/2"></div>
                            </div>
                        ))}
                        <div className="absolute h-full w-0.5 bg-indigo-200 left-1/2 top-0 transform -translate-x-1/2"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Tutorial;