import React from 'react';
import { Cloud, DollarSign, FileText } from 'lucide-react';

const Pricing = () => {

    return (
        <>
            <header className="bg-white">
                <div className="container mx-auto flex justify-between items-center py-4 px-6">
                    <div className="flex items-center">
                        <a className="text-2xl cursor-pointer font-bold text-indigo-600 flex items-center">
                            CoolCloud
                        </a>
                    </div>
                    <nav className="hidden md:flex space-x-6">
                        <a className="text-gray-600 hover:text-indigo-600 transition-colors flex items-center">
                            <FileText className="mr-1" /> Docs
                        </a>
                    </nav>
                </div>
            </header>
            <div className='sm:px-32 px-4'>
                <h2 className="text-2xl pt-4 font-bold text-gray-800 mt-10">Why CoolCloud?</h2>
                <p className="text-gray-600 mt-4">
                    CoolCloud offers the best value for your money. Compare our pricing with AWS and Render and see the significant savings you can achieve without compromising on quality or performance.
                </p>
                <PricingComparisonTable />
            </div>
            <Footer />
        </>
    );
};

export default Pricing;

const PricingComparisonTable = () => {
    const services = [
        { name: 'CoolCloud' },
        { name: 'AWS' },
        { name: 'Render' },
    ];

    const pricingData = [
        {
            name: 'Standard',
            coolcloud: { price: '$12', cpu: '1', ram: '2 GB', name: "Beta" },
            aws: { price: '$28.6', cpu: '1', ram: '2 GB', name: "c8g.medium" },
            render: { price: '$25', cpu: '1', ram: '2 GB', name: "Standard" },
        },
        { 
            name: 'Pro',
            coolcloud: { price: '$24', cpu: '2', ram: '4 GB', name: "Omega" },
            aws: { price: '$57.2', cpu: '2', ram: '4 GB', name: "c8g.large" },
            render: { price: '$85', cpu: '2', ram: '4 GB', name: "Pro" },
        }, 
        {
            name: 'Pro',
            coolcloud: { price: '$48', cpu: '4', ram: '8 GB', name: "Delta" },
            aws: { price: '$114.4', cpu: '4', ram: '8 GB', name: "c8g.xlarge" },
            render: { price: '$175', cpu: '4', ram: '8 GB', name: "Pro Plus" },
        },
    ];

    return (
        <div className="max-w-4xl mx-auto p-4 rounded-lg shadow-md bg-white mt-10">
            <h3 className="text-xl font-bold text-gray-800 mb-4">Pricing Comparison</h3>
            <table className="w-full border-collapse">
                <thead>
                    <tr className="bg-gray-200">
                        {services.map((service) => (
                            <th key={service.name} className="p-3 text-center text-gray-700 font-semibold">
                                {service.name}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {pricingData.map((row) => (
                        <tr key={row.name} className="border-b">
                            {services.map((service) => {
                                const data = row[service.name.toLowerCase()];
                                return (
                                    <td key={service.name} className="p-4 text-center">
                                        <div className="font-bold text-lg text-indigo-600">{data.price} <span className='font-light text-sm'>/month</span></div>
                                        <div className="font-semibold pt-2 text-gray-700">{data.name}</div>
                                        <div className="text-sm text-gray-600">
                                            Resources: {data.cpu} CPU, {data.ram} RAM
                                        </div>
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const Footer = () => {
    return (
        <footer className="bg-gray-900 py-6 mt-16 text-center text-gray-400 rounded-t-lg">
            <p>&copy; 2024 CoolCloud. All rights reserved.</p>
            <p className="mt-2">Contact us at <a href="mailto:contact@coolcloud.com" className="text-purple-400">contact@coolcloud.com</a></p>
        </footer>
    );
};
