import React, { useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GithubAuthProvider, setPersistence, browserLocalPersistence, updateProfile } from "firebase/auth";
import { Star, Flame, Cog, DollarSign, Users, ArrowLeft, Plus, Menu, User, CheckCheck, CircleX, CheckIcon, LoaderIcon, Globe2, ArrowRightIcon, CheckCircleIcon } from "lucide-react";
import { getFirestore, collection, getDocs, query, where, getDoc, setDoc, addDoc, serverTimestamp, deleteDoc, doc, updateDoc, arrayRemove } from 'firebase/firestore';
import { CiGlobe } from "react-icons/ci";
import { FaServer, FaClock, FaPlus, FaTrash } from 'react-icons/fa';
import { FaGlobe, FaCog, FaGithub, FaCopy } from 'react-icons/fa';
import { Trash2, Pen } from 'lucide-react';
import { Globe, Server, Package } from 'lucide-react';
import { Link } from "react-router-dom";
import { initializeApp } from "firebase/app";
import axios from "axios";
import GithubConnect from './githubConnect';

const firebaseConfig = {
  apiKey: "AIzaSyBPxueucxQtV0Zbxe8BtlT6GoSR753TNd4",
  authDomain: "gammafy-638dc.firebaseapp.com",
  projectId: "gammafy-638dc",
  storageBucket: "gammafy-638dc.appspot.com",
  messagingSenderId: "849864306670",
  appId: "1:849864306670:web:506911cfb604f41628a8ff",
  measurementId: "G-74ZH9LRDP3"
};
  
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const provider = new GithubAuthProvider();
provider.addScope('repo');

const Dashboard = ({ user, data, userPlan, setPaid, initialOptions }) => {
  const [repositories, setRepositories] = useState([]);
  const [showList, setShowList] = useState(false);
  const [indRepo, setIndRepo] = useState(null);
  const [main, setMain] = useState(true);
  const [details, setDetails] = useState()
  const [forward, setForward] = useState(true);
  const [deploying, setDeploying] = useState(false);
  const isDeployed = data?.length >= 1;

  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [showTypes, setShowTypes] = useState(true)

  const [number, setNumber] = useState(0);

  useEffect(() => {
    setNumber(data.length)
    console.log("Actuales: ", number)
  }, [data.length])

  const signInWithGitHub = () => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        return signInWithPopup(auth, provider);
      })
      .then((result) => {
        const credential = GithubAuthProvider.credentialFromResult(result);
        const newToken = credential.accessToken;
        setToken(newToken);
        setWebServiceFormData(prevData => ({
          ...prevData,
          token: newToken
        }));
        fetchRepositories(newToken);
        setShowList(true);
        setShowTypes(false)
      })
      .catch((error) => {
        console.error("Error during sign-in:", error.code, error.message);
      });
  };

  const fetchRepositories = async (accessToken, searchQuery = '', pageNum = 1) => {
    setLoading(true);
    try {
      const response = await fetch(`https://api.github.com/user/repos?page=${pageNum}&per_page=99&q=${searchQuery}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (pageNum === 1) {
        setRepositories(data);
      } else {
        setRepositories(prev => [...prev, ...data]);
      }

      setPage(pageNum);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching repositories:", error);
      setLoading(false);
    }
  };

  const plans = [
    {plan: "Beta", price: "6", cpu: "1", ram: "1"},
    { plan: "Omega", price: "12", cpu: "1", ram: "2" },
    { plan: "Delta", price: "18", cpu: "2", ram: "2" },
  ]

  const [webServiceFormData, setWebServiceFormData] = useState({
    token: token,
    webAppName: '',
    server_type: "",
    plan: "Beta",
    repoUrl: '',
    branch: 'main',
    runtime: 'Django',
    startupCommand: '',
    portNumber: "",
    location: 'ams',
    nodejsVersion: '14.x',
    envVars: []
  });

  const [nameAvailability, setNameAvailability] = useState(null);
  const appNamesRef = collection(db, "appnames");

  const checkNameAvailability = async (name) => {
    if (!name) {
      return 'Please enter a name first';
    }

    try {
      const q = query(appNamesRef, where('name', '==', name));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return 'Name available!';
      } else {
        return 'Name already taken!';
      }
    } catch (error) {
      console.error('Error checking name availability:', error);
      return 'Error checking availability';
    }
  };

  const handleCheckNameAvailability = async () => {
    const result = await checkNameAvailability(webServiceFormData.webAppName);
    setNameAvailability(result);
  };

  const goBack = () => {
    setShowList(true);
    setIndRepo(null);
    setMain(true);
    setForward(true);
    setNameAvailability(null);
    setWebServiceFormData({
      token: token,
      webAppName: '',
      repoUrl: '',
      branch: 'main',
      runtime: 'Django',
      startupCommand: '',
      portNumber: "",
      location: 'ams',
      nodejsVersion: '14.x',
      envVars: []
    });
  };
  const [activeSection, setActiveSection] = useState('main');

  const handleReload = (e) => {
    e.preventDefault();
    setActiveSection('main')
    setForward(true)
    setShowTypes(true)
    setShowList(false)
    setIndRepo(null)
  };

  const menuItems = [
    { icon: Flame, text: "Deployments", onClick: (e) => handleReload(e) },
    { icon: DollarSign, text: "Billing", onClick: () => setActiveSection('billing') },
    { icon: Users, text: "Teams", onClick: () => setActiveSection('teams') },
    { icon: User, text: "Account", onClick: () => setActiveSection('user') }
  ];

  const stats = [
    { title: "Deployments", value: `${data?.length}` },
    { title: "Team members", value: "1" }
  ];

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const [selectedType, setSelectedType] = useState(null);
  const [cronJobs, setCronJobs] = useState([{ endpoint: '', schedule: '' }]);

  const deploymentTypes = [
    { id: 'webservice', name: 'New Web Service', icon: FaServer },
  ];

  const [deploymentType, setDeploymentType] = useState('cron');

  const handleDeploymentTypeSelect = (type) => {
    if (!user) {
      signInWithGitHub();
      return;
    }

    signInWithGitHub();
    setSelectedType(type);
  };


  const [cronFormData, setCronFormData] = useState({
    webAppName: '',
    repoUrl: '',
    branch: '',
    runtime: 'Django',
    startupCommand: '',
    portNumber: "",
    location: 'ams',
    nodejsVersion: '14.x',
  });

  const handleWebServiceFormChange = (e) => {
    setWebServiceFormData({ ...webServiceFormData, [e.target.name]: e.target.value });
  };

  const deploymentsRef = collection(db, "deployments");
  const [getResponse, setGetResponse] = useState(false);
  const [successful, setSuccessful] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`https://${selectedDeployment?.endpoint}`);
    setShowTooltip(true);
  };

  useEffect(() => {
    if (showTooltip) {
      const timer = setTimeout(() => setShowTooltip(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [showTooltip]);
  
  const saveDeployment = async (websiteURL, ip, instanceId) => {
    try {
      const deploymentData = {
        uid: user.uid,
        endpoint: websiteURL,
        data: webServiceFormData,
        timestamp: serverTimestamp(),
        ip: ip,
        instanceId: instanceId
      };

      const docRef = await addDoc(deploymentsRef, deploymentData);
      const webAppName = webServiceFormData.webAppName;
      await addDoc(appNamesRef, {
        name: webAppName,
      });
      return docRef.id;
    } catch (error) {
      console.error("Error saving deployment: ", error);
      throw error;
    }
  };

  const userId = user?.uid;
  const [subscription, setSubscription] = useState(null);
  const [allowed, setAllowed] = useState()
  const [subscriptionId, setSubscriptionId] = useState()
  const [instanciaId, setInstanciaId] = useState();
  const [subsId, setSubsId] = useState()
  const [permitir, setPermitir] = useState(false);

  const [hasProfile, setHasProfile] = useState(false)

  useEffect(() => {
    if (!userId) return;
    const fetchSubscription = async () => {
      try {
        const userRef = doc(db, 'payments_profile', userId);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setSubscription(data?.subscription);
          setAllowed(data?.response?.plan)
          setHasProfile(data.active)
          setSubsId(data?.subscription.attributes.subscription_id)
          setInstanciaId(data?.instanceId)
          setSubscriptionId(data?.subscription?.attributes?.subscription_id)
          console.log("Has profile?", hasProfile)
        } else {
          setHasProfile(false)
          console.log('No subscription found for this user.');}
      } catch (error) {console.error('Error fetching subscription:', error);}
    };

    fetchSubscription();
  }, [userId]);

  const handleCronFormChange = (e) => {
    setCronFormData({ ...cronFormData, [e.target.name]: e.target.value });
  };

  const handleCronJobChange = (index, field, value) => {
    const updatedCronJobs = [...cronJobs];
    updatedCronJobs[index][field] = value;
    setCronJobs(updatedCronJobs);
  };

  const addCronJob = () => {
    setCronJobs([...cronJobs, { endpoint: '', schedule: '' }]);
  };

  const [billingToken, setBillingToken] = useState('');


  const removeCronJob = (index) => {
    const updatedCronJobs = cronJobs.filter((_, i) => i !== index);
    setCronJobs(updatedCronJobs);
  };

  const [cronSubmit, setCronSubmit] = useState()
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSubmitCron = async (e) => {
    e.preventDefault();
    setDeploying(true);
    try {
      const response = await axios.post('https://backendhectorcc.onrender.com/deploy-cron', {
        ...cronFormData,
        cronJobs,
      });
      setSuccessful(true);
      setCronSubmit(response.data);
    } catch (error) {
      setSuccessful(false);
    } finally {
      setDeploying(false);
    }
  };

  const [showTooltipX, setShowTooltipX] = useState(false);

  const handleCopyX = (text) => {
    navigator.clipboard.writeText(text);
    setShowTooltipX(true);
  };

  useEffect(() => {
    if (showTooltipX) {
      const timer = setTimeout(() => setShowTooltipX(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [showTooltipX]);

  const handleSubmitWebService = async (e) => {
    e.preventDefault();
    setDeploying(true);
    setGetResponse(true);

    const envVarsString = JSON.stringify(webServiceFormData.envVars);

    try {
      console.log({ ...webServiceFormData, envVars: envVarsString });

      const response = await axios.post(
        'https://listosraa.onrender.com/deploy',
        { ...webServiceFormData, envVars: envVarsString }
      );

      setDetails(response.data);
      setGetResponse(false);
      setSuccessful(true);

      const { endpoint: websiteURL, ip, instanceId } = response.data;

      if (websiteURL) {
        await saveDeployment(websiteURL, ip, instanceId, subscriptionId);

        const userRef = doc(db, 'payments_profile', userId);
        await setDoc(userRef, { instanceId: instanceId }, { merge: true });
      }

    } catch (error) {
      setGetResponse(false);
    } finally {
      setGetResponse(false);
      setDeploying(false);
    }
  };


  const handleSelect = (type) => {
    setSelectedType(type);
    setDeploymentType(type);
    handleDeploymentTypeSelect(type);
  };

  const repoSelected = (repo) => {
    setShowList(false);
    setForward(false);
    setIndRepo(repo);
    const formData = {
      webAppName: repo.name,
      repoUrl: repo.html_url
    };
    setCronFormData(prev => ({ ...prev, ...formData }));
    setWebServiceFormData(prev => ({ ...prev, ...formData }));
  };

  const deleteSubscription = async () => {
    try {
      const deleteInstanceResponse = await fetch(`https://listosraa.onrender.com/delete/${instanciaId}`, { method: 'DELETE' });
      if (!deleteInstanceResponse.ok) {
        throw new Error(`Failed to delete instance: ${deleteInstanceResponse.statusText}`);
      }
      window.location.reload()
    }
    catch (err) {
      console.log(err)
    }
  }

  const deleteDeployment = async (id) => {
    
    try {

      const subscriptionResponse = await fetch(`https://listosraa.onrender.com/cancel-subscription/${subsId}`, { method: 'DELETE' });
      if (!subscriptionResponse.ok) {
        throw new Error(`Failed to cancel subscription: ${subscriptionResponse.statusText}`);
      }
      

      const deleteInstanceResponse = await fetch(`https://listosraa.onrender.com/delete/${instanciaId}`, { method: 'DELETE' });
      if (!deleteInstanceResponse.ok) {
        throw new Error(`Failed to delete instance: ${deleteInstanceResponse.statusText}`);
      }

      const deploymentRef = doc(db, "deployments", id);
      await deleteDoc(deploymentRef);

      console.log("Deployment and subscription deleted successfully.");
      window.location.reload()
    } catch (error) {
      console.error("Error deleting deployment or subscription:", error);
      throw error;
    } finally {
      
    }
  };


  const [selectedDeployment, setSelectedDeployment] = useState(null);

  const handleDeploymentClick = (deployment) => {
    setSelectedDeployment(deployment);
    setActiveSection('deploymentDetails');
  };

  const [editMode, setEditMode] = useState(false);
  const [updatedDeployment, setUpdatedDeployment] = useState({ ...selectedDeployment });

  const renderDeploymentDetails = () => {
    if (!selectedDeployment) return null;

    const handleInputChange = (e) => {
      setUpdatedDeployment({
        ...updatedDeployment,
        data: { ...updatedDeployment.data, [e.target.name]: e.target.value }
      });
    };

    const handleSaveChanges = async () => {
      setEditMode(false);
      setSelectedDeployment(updatedDeployment);
    };


    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 pt-5 pb-3 sm:px-6 flex justify-between items-center">
<div className='flex items-center'>
            <CiGlobe className='w-6 mr-2 h-auto text-gray-400' />
            Web service 
</div>
          <button
            onClick={() => setEditMode(!editMode)}
            className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
          >
            {editMode ? 'Cancel' : 'Edit'}
          </button>
        </div>
<div className='px-6 pb-5'>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {selectedDeployment.data.webAppName}
          </h3>
</div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Repository</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <a href={selectedDeployment?.data?.repoUrl} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-500">
                  {selectedDeployment?.data?.repoUrl}
                </a>
              </dd>
              <dt className="text-sm flex items-center font-medium text-gray-500">Branch </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <a rel="noopener noreferrer" className="text-gray-600">
                  {selectedDeployment?.data?.branch}
                </a>
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Location</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {editMode ? (
                  <select
                    name="location"
                    value={updatedDeployment?.data?.location}
                    onChange={handleInputChange}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value="eastus">East US</option>
                    <option value="westus">West US</option>
                    <option value="centralus">Central US</option>
                  </select>
                ) : (
                  selectedDeployment?.data?.location
                )}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Pricing Tier</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {editMode ? (
                  <select
                    name="sku"
                    value={updatedDeployment?.data?.sku}
                    onChange={handleInputChange}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value="F1">Beta</option>
                    <option value="B1">Omega</option>
                    <option value="S1">Delta</option>
                  </select>
                ) : (
                  selectedDeployment?.data?.sku
                )}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Endpoint</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                <a
                  className="cursor-pointer text-indigo-500 hover:text-indigo-600 mr-2"
                  target="_blank"
                  href={`https://${selectedDeployment?.endpoint}`}
                >
                  https://{selectedDeployment?.endpoint}
                </a>
                <div className="relative">
                  <button
                    onClick={copyToClipboard}
                    className="text-gray-400 hover:text-gray-600 focus:outline-none"
                    aria-label="Copy endpoint"
                  >
                    <CopyIcon />
                  </button>
                  {showTooltip && (
                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded shadow-lg">
                      Copied!
                    </div>
                  )}
                </div>
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Delete web service</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <button
                  onClick={() => {
                    const id = selectedDeployment?.id;
                    const subscriptionId = selectedDeployment?.subscriptionId;
                    deleteDeployment(id, subscriptionId);
                  }}
                  className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500`}
                >
                Delete
                </button>
              </dd>
            </div>
          </dl>
        </div>
        {editMode && (
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              onClick={handleSaveChanges}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save Changes
            </button>
          </div>
        )}
      </div>
    );
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleEnvVarChange = (index, field, value) => {
    setWebServiceFormData(prevData => {
      const newEnvVars = [...prevData.envVars];
      newEnvVars[index] = { ...newEnvVars[index], [field]: value };
      return { ...prevData, envVars: newEnvVars };
    });
  };

  const setPlan = (selectedPlan) => {
    setWebServiceFormData(prevFormData => ({
      ...prevFormData,
      plan: selectedPlan.plan,
      server_type: selectedPlan.cpu + " CPU / " + selectedPlan.ram + " RAM"
    }));
  }

  const addEnvVar = () => {
    setWebServiceFormData(prevData => ({
      ...prevData,
      envVars: [...prevData.envVars, { key: '', value: '' }]
    }));
  };

  const removeEnvVar = (index) => {
    setWebServiceFormData(prevData => ({
      ...prevData,
      envVars: prevData.envVars.filter((_, i) => i !== index)
    }));
  };

  const [isAvailabilityButtonDisabled, setIsAvailabilityButtonDisabled] = useState()

  return (
    <div className="flex flex-col h-screen bg-gray-100 md:flex-row">
      <header className="bg-white shadow-sm md:hidden">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <Link to="/" className="text-xl font-bold text-indigo-600 flex items-center">
            CoolCloud
          </Link>
          <button onClick={toggleMobileMenu} className="text-gray-500 hover:text-gray-600">
            <Menu size={24} />
          </button>
        </div>
      </header>
      <div className={`${mobileMenuOpen ? 'block' : 'hidden'} md:block md:w-64 bg-white shadow-lg`}>
        <div className="flex items-center justify-center h-16 border-b">
          <Link to="/" className="text-xl font-bold text-indigo-600 flex items-center">
            CoolCloud
          </Link>
        </div>
        <nav className="mt-6">
          {menuItems.map((item, index) => (
            <a
              key={index}
              onClick={item.onClick}
              className="flex items-center px-6 py-3 text-gray-600 hover:bg-indigo-50 hover:text-indigo-600 cursor-pointer"
            >
              <item.icon className="mr-3" size={20} />
              {item.text}
            </a>
          ))}
        </nav>
      </div>

      <div className="flex-1 overflow-y-auto">
        <header className="bg-white shadow-sm hidden md:block">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <h1 className="text-2xl font-semibold text-gray-900"></h1>
            <div className="bg-indigo-600 text-white w-10 h-10 flex items-center justify-center rounded-full font-bold">
              <img className='rounded-full' src={user?.photoURL} alt='' />
            </div>
          </div>
        </header>
        <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          {activeSection === 'main' && (
            <>
              <div className="pb-6 flex items-center">
                <p className='text-xl font-semibold'>{user?.displayName}</p>
                <button className='px-3 pt-1 font-semibold rounded-lg text-sm bg-gray-200 ml-2'>{userPlan}</button>
              </div>
              {forward && (
                <>
                  <div className="mb-8">
                    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                      {stats.map((item, index) => (
                        <div key={index} className="bg-white overflow-hidden shadow rounded-lg">
                          <div className="px-4 py-5 sm:p-6">
                            <dt className="text-sm font-medium text-gray-500 truncate">{item.title}</dt>
                            <dd className="mt-1 text-2xl md:text-3xl font-semibold text-gray-900">{item.value}</dd>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {showTypes && (
                    <>
                      <div className="grid grid-cols-2 gap-4 mb-4">
                        {deploymentTypes.map((type) => (
                          <button
                            key={type.id}
                            onClick={() => handleSelect(type.id)}
                            className={`flex items-center justify-center p-4 border rounded-lg ${selectedType === type.id
                                ? 'bg-indigo-100 border-indigo-500'
                                : 'border-gray-300 hover:border-indigo-500'
                              } ${(number >= 1 || hasProfile===false) ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={number>=1 || hasProfile===false}
                          >
                            <type.icon className="mr-2 text-indigo-600" size={20} />
                            <span>{type.name}</span>
                          </button>
                        ))}
                      </div>
                      {hasProfile === false && <p className='pt-4 font-light italic text-indigo-600'>Please choose a subscription in the billing section before deploying a new web service</p>}

                      <div className="mt-8">
                        {data?.length > 0 &&
                          <h2 className="text-2xl font-semibold text-gray-900 mb-6">My Deployments</h2>
                        }
                        <div className="grid grid-cols-1 gap-6">
                          {data.map((element, index) => (
                            <div key={index} className="bg-white overflow-hidden shadow rounded-lg">
                              <div className="px-4 py-5 sm:p-6">
                                <div onClick={() => handleDeploymentClick(element)} className='flex items-center cursor-pointer'>
                                  <h3 className="text-lg font-medium underline text-gray-900 truncate mb-2">
                                    {element?.data?.webAppName}
                                  </h3>
                <ArrowRightIcon className='text-gray-900 w-6 h-auto pl-2' />
                              </div>
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                                  <div className="sm:col-span-1 flex items-center">
                                    <dt className="text-sm font-medium text-gray-500 flex items-center mb-1">
                                      <Globe className="mr-2 h-5 w-5 text-indigo-500" />
                                      Location
                                    </dt>
                                    <dd className="text-sm ml-2 text-gray-900 bg-indigo-50 rounded-md px-2 py-1 inline-block">
                                      {element?.data?.location}
                                    </dd>
                                  </div>
                                  <div className="sm:col-span-1 flex items-center">
                                    <dt className="text-sm font-medium text-gray-500 flex items-center mb-1">
                                      <Server className="mr-2 h-5 w-5 text-indigo-500" />
                                      Runtime
                                    </dt>
                                    <dd className="text-sm ml-2 text-gray-900 bg-indigo-50 rounded-md px-2 py-1 inline-block">
                                      {element?.data.runtime}
                                    </dd>
                                  </div>
                                  <div className="sm:col-span-1 flex items-center">
                                    <dt className="text-sm font-medium text-gray-500 flex items-center mb-1">
                                      <Package className="mr-2 h-5 w-5 text-indigo-500" />
                                      Tier
                                    </dt>
                                    <dd className="text-sm ml-2 text-gray-900 bg-indigo-50 rounded-md px-2 py-1 inline-block">
                                      {element?.data?.sku}
                                    </dd>
                                  </div>
                                </dl>
                                <div className="mt-6 space-y-2">
                                  <div className="text-sm underline text-indigo-600 hover:text-indigo-500 flex items-center">
                                    <Globe className="mr-2 h-4 w-4" />
                                    <a href={`https://${element?.endpoint}`} target='_blank' className='cursor-pointer'>
                                      https://{element?.endpoint}
</a>
                                  </div>
                                  <a href={element?.data?.repoUrl} target="_blank" rel="noopener noreferrer" className="text-sm text-gray-600 hover:text-gray-500 flex items-center">
                                    <FaGithub className="mr-2 h-4 w-4" />
                                    {element?.data?.repoUrl}
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {showList && <GithubConnect searchTerm={searchTerm} setSearchTerm={setSearchTerm} setForward={setForward} repoSelected={repoSelected} repos={repositories} />}
              {indRepo && (
                <div className="mt-3">
                  <button onClick={goBack} className="flex items-center text-indigo-600 hover:text-indigo-800 mb-6">
                    <ArrowLeft className="mr-2" size={20} />
                    <span className="font-semibold">Go back</span>
                  </button>
                  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                  {selectedType === "webservice" && (
                    <>
                        <div className="px-4 py-5 sm:px-6">
                          <h3 className="text-lg leading-6 mb-2 font-medium text-gray-900">Deploy your project</h3>
                          <p className="mb-3 mt-8 max-w-2xl text-sm text-gray-500">
                            Configuration for {indRepo.name}
                          </p>
                          <div>
                            {details && (
                              <div className="flex items-center">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-indigo-600 flex items-center"
                                >
                                  <p className='text-black no-underline mr-2'>Your endpoint: </p>  
                                  <a
                                    href={`https://${details?.endpoint}`}
                                    className='cursor-pointer hover:underline'
                                  >https://{details?.endpoint}</a> 
                                  <CheckCircleIcon className='text-green-500 w-4 h-auto ml-2' />
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                          <form onSubmit={handleSubmitWebService}>
                            <dl className="sm:divide-y sm:divide-gray-200">
                              {[
                                {
                                  label: "Deployment Name",
                                  description: "Enter a unique name for your deployment. Check if it is available.",
                                  name: "webAppName",
                                  type: "text",
                                  validate: (value) => {
                                    const sanitized = value.replace(/[^a-z-]/g, '');
                                    return sanitized.replace(/^[0-9]/, '');
                                  },
                                  transform: (value) => {
                                    return value.replace(/\s+/g, '-');
                                  }
                                },
                                { label: "Repository URL", description: "This is the link from your GitHub repository", name: "repoUrl", type: "text", disabled: true },
                                { label: "Branch", description: "Specify the branch to deploy", name: "branch", type: "text" },
                                {
                                  label: "Runtime",
                                  description: "Select the runtime environment for your application",
                                  name: "runtime",
                                  type: "select",
                                  options: ["Django", "NodeJS"]
                                },
                                {
                                  label: "Location",
                                  description: "Choose the server location for your deployment",
                                  name: "location",
                                  type: "select",
                                  labels: [
                                    "Amsterdam (Netherlands)",
                                    "Atlanta (Georgia, USA)",
                                    "New Jersey (New Jersey, USA)",
                                    "Paris (France)",
                                    "Dallas (Texas, USA)",
                                    "Frankfurt (Germany)",
                                    "Hong Kong",
                                    "Los Angeles (California, USA)",
                                    "London (United Kingdom)",
                                    "Manchester (United Kingdom)",
                                    "Miami (Florida, USA)",
                                    "Mexico City (Mexico)"
                                  ],
                                  options: [
                                    "ams",
                                    "atl",
                                    "ewr",
                                    "cdg",
                                    "dfw",
                                    "fra",
                                    "hkg",
                                    "lax",
                                    "lhr",
                                    "man",
                                    "mia",
                                    "mex"
                                  ]
                                },
                                ...(webServiceFormData.runtime === "NodeJS" ? [
                                  {
                                    label: "Startup Command",
                                    description: "Command to start your application. Typically, it is node index.js",
                                    name: "startupCommand",
                                    type: "text"
                                  },
                                  {
                                    label: "Port Number",
                                    description: "The port number on which your server is running (Default is 5000)",
                                    name: "portNumber",
                                    type: "text"
                                  },
                                  {
                                    label: "NodeJS version",
                                    description: "Select the version of NodeJS for your application. You can find it by running node -v",
                                    name: "nodejsVersion",
                                    type: "select",
                                    options: ["14.x", "16.x", "18.x", "20.x", "22.x"]
                                  }
                                ] : []),
                              ].map((field, index) => (
                                <div key={index} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-lg font-medium text-gray-800">
                                    {field.label}
                                    {field.description && (
                                      <p className="mt-1 text-sm font-light text-gray-700">{field.description}</p>
                                    )}
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <div className="flex items-center">
                                      {field.type === "select" ? (
                                        <select
                                          name={field.name}
                                          value={webServiceFormData[field.name]}
                                          onChange={handleWebServiceFormChange}
                                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        >
                                          {field.name === "location"
                                            ? field.labels.map((label, optionIndex) => (
                                              <option key={optionIndex} value={field.options[optionIndex]}>
                                                {label}
                                              </option>
                                            ))
                                            : field.options.map((option, optionIndex) => (
                                              <option key={optionIndex} value={option}>
                                                {option}
                                              </option>
                                            ))}
                                        </select>
                                      ) : (
                                        <input
                                          type={field.type}
                                          name={field.name}
                                          value={webServiceFormData[field.name]}
                                          onChange={(e) => {
                                            let value = e.target.value;
                                            if (field.validate) {
                                              value = field.validate(value);
                                            }
                                            if (field.transform) {
                                              value = field.transform(value);
                                            }
                                            handleWebServiceFormChange({
                                              target: { name: field.name, value }
                                            });

                                            if (field.name === 'webAppName') {
                                              const isValid = /^[a-z-]*$/.test(value);
                                              e.target.style.borderColor = isValid ? 'initial' : 'red';
                                              setNameAvailability(null);
                                              setIsAvailabilityButtonDisabled(!isValid);
                                            }
                                          }}
                                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                          required
                                          disabled={field.disabled}
                                        />
                                      )}
                                      {field.name === 'webAppName' && (
                                        <button
                                          type="button"
                                          onClick={handleCheckNameAvailability}
                                          className="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                          disabled={isAvailabilityButtonDisabled}
                                        >
                                          Availability
                                        </button>
                                      )}
                                    </div>
                                    {field.name === 'webAppName' && nameAvailability && (
                                      <div className={`mt-2 font-medium text-sm ${nameAvailability === 'Name available!' ? 'text-green-500' : 'text-red-600'}`}>
                                        {nameAvailability}
                                      </div>
                                    )}
                                  </dd>
                                </div>
                              ))}
                            </dl>
                            <div className="mt-6 sm:px-6">
                              <h3 className="text-lg font-medium text-gray-900">Environment Variables</h3>
                              {webServiceFormData.envVars.map((envVar, index) => (
                                <div key={index} className="mt-2 flex items-center space-x-2">
                                  <input
                                    type="text"
                                    placeholder="Key"
                                    value={envVar.key}
                                    onChange={(e) => handleEnvVarChange(index, 'key', e.target.value)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  />
                                  <input
                                    type="text"
                                    placeholder="Value"
                                    value={envVar.value}
                                    onChange={(e) => handleEnvVarChange(index, 'value', e.target.value)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  />
                                  <button
                                    type="button"
                                    onClick={() => removeEnvVar(index)}
                                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                  >
                                    Remove
                                  </button>
                                </div>
                              ))}
                              <button
                                type="button"
                                onClick={addEnvVar}
                                className="mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-green-500"
                              >
                                Add Environment Variable
                              </button>
                            </div>
                            <div className='grid grid-cols-1 sm:grid-cols-3'>
                              {plans?.map((element, index) => (
                                <div
                                  key={index}
                                  className={`rounded py-3 px-4 mx-10 my-4 border ${element.plan === allowed ? 'border-indigo-600 hover:border-2 cursor-pointer' : 'border-gray-400 cursor-not-allowed'}`}
                                  onClick={() => element.plan === allowed && setPlan(element)}
                                >
                                  <div className='flex justify-between items-center'>
                                    <p className='font-semibold text-gray-800 text-lg'>{element?.plan}</p>
                                    <p className='text-gray-600 font-medium'>{element?.price} USD / month</p>
                                  </div>
                                  <div className='flex justify-between items-center'>
                                    <p className='text-gray-600 font-medium'>{element?.cpu} CPU</p>
                                    <p className='text-gray-600 font-medium'>{element?.ram} RAM</p>
                                  </div>
                                </div>
                              ))}
</div>
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                              <button
                                type="submit"
                                className={`inline-flex justify-center py-2 px-4 border shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${deploying || nameAvailability !== 'Name available!'
                                    ? 'bg-gray-400 cursor-not-allowed text-gray-700 border-gray-300'
                                    : 'text-white bg-indigo-600 hover:bg-indigo-700 mb focus:ring-indigo-500'
                                  }  ${getResponse === false && successful === false && nameAvailability === "Name available!"? "mb-24" : ""} `}
                                disabled={deploying || nameAvailability !== 'Name available!'}
                              >
                                {deploying ? 'Deploying...' : `Deploy`}
                              </button>
                              {!deploying && nameAvailability !== 'Name available!' &&
                              <p className='text-red-500 pt-2 pb-12'>Check if the name is available first!</p>
                              }
                            </div>
                            {getResponse &&
                              <div className=' flex justify-center'>
                                <div className='rounded py-4 mt-4 mb-24 px-12 flex justify-center items-center bg-yellow-100'>
                                  <LoaderIcon className='text-yellow-600 w-5 animate-spin h-auto mr-1' />
                                  <p className="text-sm font-semibold text-yellow-600">
                                    Deploying... Please wait.
                                  </p>
                                </div>
                              </div>
                            }
                            {successful && details &&
                              <div className='flex justify-center'>
                                <div className='rounded flex items-center py-4 px-12 mt-4 mb-24 bg-green-100'>
                                  <CheckIcon className='text-green-600 w-5 h-auto mr-1' />
                                  <p className='text-green-600'> Your web service has been successfully deployed </p>
                                </div>
                              </div>
                            }
                          </form>
                        </div>
                    </>
                  )}
                    {selectedType === 'cron' && (
                      <form onSubmit={handleSubmitCron}>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                          <dl className="sm:divide-y sm:divide-gray-200">
                            {[
                              { label: "Deployment Name", name: "webAppName", type: "text" },
                              { label: "Repository URL", name: "repoUrl", type: "text" },
                              { label: "Branch", name: "branch", type: "text" },
                              { label: "Runtime", name: "runtime", type: "select", options: ["NodeJS", "Django"] },
                              { label: "Startup Command", name: "startupCommand", type: "text" },
                              { label: "Port Number", name: "portNumber", type: "text" },
                              { label: "Location", name: "location", type: "select", options: ["east-us-1", "us-east-2", "us-west-1"] },
                              { label: "NodeJS version", name: "nodejsVersion", type: "select", options: ["14.x", "16.x", "18.x", "20.x", "22.x"] },
                            ].map((field, index) => (
                              <div key={index} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">{field.label}</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                  <input
                                    type={field.type}
                                    name={field.name}
                                    value={cronFormData[field.name]}
                                    onChange={handleCronFormChange}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  />
                                </dd>
                              </div>
                            ))}
                          </dl>
                          <div className="mt-6 px-4">
                            <h4 className="text-lg font-medium text-gray-900 mb-4">Cron Jobs</h4>
                            {cronJobs.map((job, index) => (
                              <div key={index} className="flex px-4 items-center space-x-4 mb-4">
                                <input
                                  type="text"
                                  value={job.endpoint}
                                  onChange={(e) => handleCronJobChange(index, 'endpoint', e.target.value)}
                                  placeholder="Endpoint"
                                  className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                                <input
                                  type="text"
                                  value={job.schedule}
                                  onChange={(e) => handleCronJobChange(index, 'schedule', e.target.value)}
                                  placeholder="Cron Schedule"
                                  className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                                <button
                                  type="button"
                                  onClick={() => removeCronJob(index)}
                                  className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                >
                                  <FaTrash className="h-5 w-5" aria-hidden="true" />
                                </button>
                              </div>
                            ))}
                            <button
                              type="button"
                              onClick={addCronJob}
                              className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              <FaPlus className="mr-2" /> Add Cron Job
                            </button>
                          </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                          <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            disabled={deploying}
                          >
                            {deploying ? 'Deploying...' : 'Deploy Cron Job'}
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          {activeSection === 'deploymentDetails' && renderDeploymentDetails()}
          {activeSection === 'billing' && <Billing deleteSubscription={deleteSubscription} hasProfile={hasProfile} subscription={subscription} setSubscription={setSubscription} userId={userId} user={user} />}
          {activeSection === 'teams' && <Teams />}
          {activeSection === 'user' && <AccountSettings user={user} />}
        </main>
      </div>
    </div>
  );
};

export default Dashboard;

const Bplans = [
  { plan: "Beta", price: "6", cpu: "1", ram: "1", variantId: "531421" },
  { plan: "Omega", price: "12", cpu: "1", ram: "2", variantId: "531422" },
  { plan: "Delta", price: "18", cpu: "2", ram: "2", variantId: "531423" }
];

const Billing = ({ user, subscription, deleteSubscription, hasProfile, userId }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubscribe = async () => {
    if (!selectedPlan) {
      setError('Please select a plan before subscribing.');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('https://listosraa.onrender.com/checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          variantId: selectedPlan.variantId,
          userId: user.uid,
          userEmail: user.email,
        }),
      });

      if (!response.ok) {
        console.log("Error: ", response);
        throw new Error('Failed to create checkout link');
      }

      const responseData = await response.json();
      const { checkoutUrl } = responseData;

      const docData = {
        uid: user.uid,
        response: {
          plan: selectedPlan.plan,
          cpu: selectedPlan.cpu,
          ram: selectedPlan.ram,
          variantId: selectedPlan.variantId,
          status: response.status,
          ok: response.ok,
          url: response.url,
        },
        checkoutUrl: checkoutUrl,
        createdAt: new Date().toISOString()
      };

      const userRef = doc(db, 'payments_profile', userId);
      await setDoc(userRef, docData);

      window.location.href = checkoutUrl;

    } catch (error) {
      console.error('Error creating checkout link:', error);
      setError('Failed to initiate checkout. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4">Billing</h2>

      {(subscription && hasProfile) ? (
        <div className="bg-white w-full sm:w-1/2 shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-8">
            <h3 className=" font-semibold mb-6">Your Current Subscription</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <p className="text-gray-600 mb-2">Status</p>
                <div className='flex text-sm items-center space-x-3'>
                  <p className="text-sm">{subscription.attributes.status_formatted}</p>
                  <CheckCircleIcon className='w-4 h-auto text-green-600' />
                </div>
              </div>
              <div>
                <p className="text-gray-600 text-sm mb-2">Plan</p>
                <p className="text-sm">{subscription.attributes.user_name}</p>
              </div>
              <div>
                <p className="text-gray-600 mb-2">Invoice Date</p>
                <p className="text-sm">{formatDate(subscription.attributes.created_at)}</p>
              </div>
              <div>
                <p className="text-gray-600 text-sm mb-2">Amount</p>
                <p className="text-sm">{subscription.attributes.total_formatted} USD</p>
              </div>
              <div>
                <p className="text-gray-600 text-sm mb-2">Payment Method</p>
                <p className="text-sm">
                  {subscription.attributes.card_brand.toUpperCase()} ending in {subscription.attributes.card_last_four}
                </p>
              </div>
              <div>
                <p className="text-gray-600 text-sm mb-2">Cancel Subscription</p>
                <button onClick={deleteSubscription} className='px-5 py-2 rounded text-sm text-white bg-red-500 hover:bg-red-600'>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-8">
          <h3 className="text-2xl font-semibold mb-6">Select a Plan</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {Bplans.map((plan) => (
              <div
                key={plan.plan}
                className={`border p-6 rounded-lg cursor-pointer transition duration-300 ${selectedPlan?.plan === plan.plan
                    ? 'border-indigo-500 bg-blue-50'
                    : 'border-gray-200 hover:border-indigo-300 hover:bg-gray-50'
                  }`}
                onClick={() => setSelectedPlan(plan)}
              >
              <div className='flex justify-between'>
              <div>
                    <h4 className="font-medium mb-2">{plan.plan}</h4>
                    <p className="text- mb-4">${plan.price}<span className="text-sm font-normal">/month</span></p>
                    </div>
                    <div>
                    <p>CPU: {plan.cpu} core(s)</p>
                    <p>RAM: {plan.ram} GB</p>
                </div>
                </div>
                <ul className="space-y-2">
                </ul>
              </div>
            ))}
          </div>
          <button
            onClick={handleSubscribe}
            disabled={isLoading || !selectedPlan}
            className="mt-8 bg-indigo-500 text-white px-6 font-semibold py-2 rounded-md hover:bg-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed transition duration-300"
          >
            {isLoading ? 'Processing...' : 'Subscribe Now'}
          </button>
        </div>
      )}

      {error && (
        <div className="mt-6 text-red-600 bg-red-100 border border-red-400 rounded-md p-4">
          {error}
        </div>
      )}
    </div>
  );
};

const Teams = () => {
  const [newEditor, setNewEditor] = useState({ name: '', email: '' });
  const [teamMembers, setTeamMembers] = useState([]);
  const [error, setError] = useState('');

  const fetchTeams = async () => {
    const user = auth.currentUser;
    if (user) {
      const q = query(collection(db, 'teams'), where('members', 'array-contains', user.uid));
      const querySnapshot = await getDocs(q);
      const members = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTeamMembers(members);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  const handleInputChange = (e) => {
    setNewEditor({ ...newEditor, [e.target.name]: e.target.value });
  };

  const handleAddEditor = async (e) => {
    e.preventDefault();
    setError('');

    if (!newEditor.name || !newEditor.email) {
      setError('Please fill in all fields.');
      return;
    }

    try {
      const user = auth.currentUser;
      await addDoc(collection(db, 'teams'), {
        ownerId: user.uid,
        members: [user.uid],
        name: newEditor.name,
        email: newEditor.email,
        role: 'Editor'
      });

      setNewEditor({ name: '', email: '' });
      fetchTeams();
    } catch (error) {
      setError('Failed to add editor. Please try again.');
      console.error('Error adding editor:', error);
    }
  };

  const handleRemoveEditor = async (editorId) => {
    try {
      const user = auth.currentUser;
      const teamDoc = doc(db, 'teams', editorId);
      await updateDoc(teamDoc, {
        members: arrayRemove(user.uid)
      });
      fetchTeams();
    } catch (error) {
      setError('Failed to remove editor. Please try again.');
      console.error('Error removing editor:', error);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4">Teams</h2>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Your Team</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Manage your team members here.</p>
        </div>
        <div className="border-t border-gray-200">
          <ul className="divide-y divide-gray-200">
            {teamMembers.map((member) => (
              <li key={member.id} className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">{member.name}</div>
                      <div className="text-sm text-gray-500">{member.email}</div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="text-sm text-gray-500 mr-4">{member.role}</div>
                    {member.ownerId !== auth.currentUser.uid && (
                      <button
                        onClick={() => handleRemoveEditor(member.id)}
                        className="text-red-600 hover:text-red-800"
                      >
                        <Trash2 size={20} />
                      </button>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Add New Editor</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Invite a new team member as an editor.</p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <form onSubmit={handleAddEditor} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={newEditor.name}
                onChange={handleInputChange}
                className="mt-1 focus:ring-indigo-500 p-2 border border-indigo-300 outline-none focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={newEditor.email}
                onChange={handleInputChange}
                className="mt-1 focus:ring-indigo-500 p-2 border border-indigo-300 outline-none focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add Editor
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const AccountSettings = ({ user }) => {
  const [editMode, setEditMode] = useState({
    email: false,
    fullName: false,
    githubUsername: false
  });
  const [userData, setUserData] = useState({
    email: user?.email,
    fullName: user?.displayName,
    githubUsername: ''
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData(prevState => ({
            ...prevState,
            githubUsername: data.githubUsername || ''
          }));
        }
      }
    };
    fetchUserData();
  }, [user]);

  const handleEdit = (field) => {
    setEditMode({ ...editMode, [field]: true });
  };

  const handleSave = async (field) => {
    setEditMode({ ...editMode, [field]: false });
    const userDocRef = doc(db, 'users', user.uid);

    try {
      if (field === 'fullName') {
        await updateProfile(auth.currentUser, { displayName: userData.fullName });
      }
      await updateDoc(userDocRef, { [field]: userData[field] });
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  const handleChange = (e, field) => {
    setUserData({ ...userData, [field]: e.target.value });
  };

  const renderField = (label, field, value) => (
    <li className="px-4 py-4 sm:px-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="text-sm font-medium text-gray-900">{label}</div>
        </div>
        <div className="flex items-center">
          {editMode[field] ? (
            <>
              <input
                type="text"
                value={value}
                onChange={(e) => handleChange(e, field)}
                className="text-sm text-gray-500 mr-2 border rounded px-2 py-1"
              />
              <button
                onClick={() => handleSave(field)}
                className="text-green-600 hover:text-green-800"
              >
                Save
              </button>
            </>
          ) : (
            <>
              <div className="text-sm text-gray-500 mr-2">{value}</div>
              <button
                onClick={() => handleEdit(field)}
                className="text-blue-600 hover:text-blue-800"
              >
                <Pen size={16} />
              </button>
            </>
          )}
        </div>
      </div>
    </li>
  );

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4">Account Settings</h2>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Profile</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Manage your account information here.</p>
        </div>
        <div className="border-t border-gray-200">
          <ul className="divide-y divide-gray-200">
            {renderField('Contact Email', 'email', userData.email)}
            {renderField('Full Name', 'fullName', userData.fullName)}
            {renderField('GitHub Username', 'githubUsername', userData.githubUsername)}
          </ul>
        </div>
      </div>
    </div>
  );
};

const CopyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
  </svg>
);
